.wrapper {
  display: flex;
  gap: var(--spacing);
}

.channel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing);
  font: var(--small);
}
