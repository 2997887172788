:root {
  /* colors */
  --carbon: #333333;
  --pitch-black: #000;
  --barley-50-percent: #fbf7ed;

  /* font settings */
  --h1: 400 2.5rem/1.2 'ivyjournal';
  --h2: 400 2rem/1.2 'ivyjournal';
  --h3: 400 1.5rem/1.2 'ivyjournal';
  --h4: 600 1rem/1.4 'Montserrat', sans-serif;
  --p: 400 1rem/1.4 'Montserrat', sans-serif;
  --small: 400 0.75rem/1.4 'Montserrat', sans-serif;
  /* constants */
  --navbar-height: 56px;
  --footer-overlap: 80px;
  --footer-waves-height: 35px;

  /* spacing */
  --spacing: 1rem;
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  /* z-indexes */
  --z-navbar: 25;
}

html,
body {
  padding: 0;
  margin: 0;
  background: var(--barley-50-percent);
}

html {
  font-size: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.4;
}

body {
  font-size: 1rem;
  color: #333;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  background: none;
  padding: 0;
  text-align: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  letter-spacing: inherit;
}

button:focus {
  outline: none;
}

select,
input,
textarea,
button {
  border-radius: 0;
  border: 0;
  font-family: 'Montserrat', sans-serif;
}

select {
  appearance: none;
}

select:focus::-ms-value {
  background: transparent;
}

select::-ms-expand {
  display: none;
}

ul,
ol {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  quotes: '\201C' '\201D' '\201C' '\201D';
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
